/** @jsx jsx */
import { jsx } from 'theme-ui'

export default function Button({variant = 'primary', ...props}) {
    const button = <button
        {...props}
        sx={{
            display: 'inline-block',
            color: 'white',
            textAlign: 'left',
            textDecoration: 'none',
            borderRadius: '5rem',
            appearance: 'none',
            border: 'none',
            outline: 'none',
            fontWeight: 'bold',
            variant: `buttons.${variant}`,
        }} onClick={props.onClick}>
        <span sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{props.children}</span>
    </button>;

    if (props.buttonUrl) {
        return (
            <a href={props.buttonUrl}>
                {button}
            </a>
        )
    }

    return button;
}