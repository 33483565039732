/** @jsx jsx */
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { jsx } from "theme-ui"
import IconDirections from "../../icons/compass-direction.svg"
import IconPhone from "../../icons/phone-circle.svg"
import IconClose from "../../icons/shop-sign-close.svg"
// Icons
import IconOpen from "../../icons/shop-sign-open.svg"
import ScaledText from "../ScaledText";

const IconTextContainer = props => (
    <div sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 1,
        py: 1,
        svg: {
            mr: 2,
            width: props.iconSize === 'big' ? '1.5rem' : '1rem',
            height: props.iconSize === 'big' ? '1.5rem' : '1rem',
            fill: props.fill ?  props.fill : 'white',
            opacity: '0.6'
        },
        a: {
            color: props.color ?  props.color : 'white',
        }
    }}>{props.children}</div>
)

const CurrentlyOpen = ({hour, ...props}) => {
    return (
    <span>
        {props.isOpen ? (<IconOpen />) : (<IconClose />)} We are currently <strong>{props.isOpen ? 'OPEN' : 'CLOSED'}</strong> <span>({props.isOpen == false && 'until '}{hour}) </span>
        <AnchorLink to={`${props.path}#footer`} sx={{ '&:hover':{textDecoration: 'none'}}} >View hours</AnchorLink>
    </span>
)}

export default function IconText(props) {
    switch(props.variant) {
        case "hours":
            return(
                <IconTextContainer fill={props.fill} color={props.color}>
                    <CurrentlyOpen isOpen={props.isOpen} hour={props.openOrClosedUntil} path={props.path}/>
                </IconTextContainer>
            )
        case "phone":
            return (
                <IconTextContainer fill={props.fill} color={props.color} iconSize={props.iconSize}><IconPhone /> {props.text}</IconTextContainer>
            )
        case "directions-nav-bar":
            return (
                <IconTextContainer fill={props.fill} color={props.color} iconSize={props.iconSize}>
                        <IconDirections />
                        <a href={`https://www.google.com/maps/search/${props.text}`} target={'_blank'}>
                            <ScaledText>
                                {props.text}
                            </ScaledText>
                        </a>
                </IconTextContainer>
            )
        case "directions":
            return (
                <IconTextContainer fill={props.fill} color={props.color} iconSize={props.iconSize}><IconDirections /> <h2 sx={{fontSize: 1, fontWeight: 'body'}}><a href={`https://www.google.com/maps/search/${props.text}`} target={'_blank'}>{props.text}</a></h2></IconTextContainer>
            )
        default:
            return (<div>Please pick a variant</div>)
    }
}