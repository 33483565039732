/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../../components/layout"
import IconText from "./IconText"

export default function SecondaryNav({...props}) {
    let isOpen = false;
    let address = null;
    let phone = null;
    let openOrClosedUntil = null;

    if (props.location) {
        if (props.location.is_open) {
            isOpen = true;
        }

        if (props.location.address) {
            address = props.location.address;
        }

        if (props.location.open_or_closed_until) {
            openOrClosedUntil = props.location.open_or_closed_until;
        }

        if (props.location.primary_phone) {
            phone = props.location.primary_phone;
        }
    }

    return(
        <div sx={{
            bg: 'secondaryColor',
            width: '100%',
            color: 'headerFontColor'
        }}>
            <Layout variant="wide">
                <div sx={{
                    display: 'flex',
                    height: '2rem',
                    px: [3,3,0],
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div sx={{display: ['none', 'none', 'inline']}}>
                        <IconText variant="hours"
                                  fill={'headerFontColor'}
                                  color={'headerFontColor'}
                                  isOpen={isOpen}
                                  openOrClosedUntil={openOrClosedUntil}
                                  path={props.path}
                        />
                    </div>
                    <div sx={{display: 'flex'}}>
                        <span>
                            <IconText variant="directions-nav-bar"
                                      fill={'headerFontColor'}
                                      color={'headerFontColor'}
                                      text={address
                                ? address
                                : '28 Waverly PI, San Francisco, CA 94108?'
                            }/>
                        </span>
                        <span sx={{display: ['none', 'none', 'flex'], '> *':{ml: 3}}}>
                            <IconText variant="phone"
                                      fill={'headerFontColor'}
                                      color={'headerFontColor'}
                                      text={phone
                                ? phone
                                : '(123) 456 7890'
                            } />
                        </span>
                    </div>
                </div>
            </Layout>
        </div>
    )
}