/** @jsx jsx */
import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { jsx } from "theme-ui"
import theme from "../../gatsby-plugin-theme-ui/index"

export class LogoImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {dimensions: {}};
        this.onLoad = this.onLoad.bind(this);
    }

    onLoad({target:img}) {
        this.setState({
            dimensions:{
                height : img.offsetHeight,
                width:  img.offsetWidth}
            }
        );
    }

    render() {
        const width = this.state.dimensions.width;
        const height = this.state.dimensions.height;

        return(
            <img
                onLoad={this.onLoad}
                src={this.props.src}
                alt={this.props.alt}
                width={width}
                height={height}
            />
        )
    }
}

export default function Logo(props) {
    const data = useStaticQuery(graphql`
    query {
        file(name: {eq: "logo"}) {
            childImageSharp {
                fluid(maxWidth: 128) {
                ...GatsbyImageSharpFluid
                }
            }
        }
    }
    `);

    return (
        <AnchorLink to={`${props.path}#home`} title="Go to top">
                <div sx={{
                    display: 'inline-block',
                    width: ['6rem','9rem','11rem'],
                    bg: 'secondaryColor',
                    p: [4, 5, 5, 5],
                    borderRadius: props.variant === 'header' ? `0 0 ${theme.radii[1]}px ${theme.radii[1]}px` : `${theme.radii[1]}px`,
                    position: props.variant === 'header' ? 'absolute' : 'static',
                    top: props.variant === 'header' ? ['2rem', '2rem', 0] : 0,
                    left: '50%',
                    ml: props.variant === 'header' ? ['-3rem','-4.5rem','-5.5rem'] : 0,
                    transform: (props.scrolled ? ['scale(0.9)', null, 'scale(0.8)'] : null),
                    transformOrigin: 'top center',
                    transition: '0.2s ease-in-out transform',
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}>
                    {props.logo
                        && <LogoImg sx={{
                                    margin: 'auto'
                                }}
                               src={props.logo}
                               alt={props.locationName ? `${props.locationName} logo` : "logo"}
                        />
                    }
                </div>
        </AnchorLink>
    )
}