/** @jsx jsx */
import React, { Suspense } from "react"
import { jsx } from 'theme-ui'
import style from "../components/ToastifyNotificationStyles"
import Header from "../components/Sections/Header"
import Hero from "../components/Sections/Hero"
import SEO from '../components/seo'
import "./reset.css"
import {Utils} from "../utils/utils";
import theme from "../gatsby-plugin-theme-ui/index";
import RenderWhenMounted from "../components/RenderWhenMounted";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../components/Button/button"
import "../fonts/fonts.css";
import "@fontsource/lato";

const TestimonialsLazy = React.lazy(() =>
    import("../components/Sections/Testimonials")
);

const MenuLazy = React.lazy(() =>
    import("../components/Sections/Menu")
);

const OurStoryLazy = React.lazy(() =>
    import("../components/Sections/OurStory")
);

const GalleryLazy = React.lazy(() =>
    import("../components/Sections/Gallery")
);

const CouponsLazy = React.lazy(() =>
    import("../components/Sections/Coupons")
);

const FooterLazy = React.lazy(() =>
    import("../components/Sections/Footer")
);

const Notification = ({ closeToast, toastProps, ...props }) => (
    <div style={style.container}>
        <div style={style.title}>
            {props.title}
        </div>
        <div style={style.message}>
            {props.message}
        </div>
        <div style={{ml:'auto', mr: 'auto'}}>
            <Button buttonUrl={props.buttonUrl} style={style.button} onClick={closeToast}>{props.buttonLabel}</Button>
        </div>
    </div>
)

export default class Home extends React.Component {
  addNotification = () => {
    toast(<Notification
          buttonLabel={Utils.getSetting('website_announcement_button_label')}
          title={Utils.getSetting('website_announcement_title')}
          message={Utils.getSetting('website_announcement_message')}
          buttonUrl={Utils.getSetting('website_announcement_link_to_button')}          
        />,
        {
        position: "bottom-right",
        autoClose: false,
        closeOnClick: true
    });
  };

  render() {
    const restaurant = Utils.getSetting('website_restaurant');
    const restaurantName = (restaurant) ? restaurant.account_name : 'The Silver Dragon';
		const restaurantPhone = (restaurant) ? restaurant.primary_phone : '213213213';
    const menu = (restaurant) ? restaurant.menu : null;
    const mapUrl = (restaurant) ? restaurant.map_url : null;
    const onlineOrderingUrl = (restaurant) ? restaurant.online_ordering_url : null;
    const locationPath = this.props.location.pathname;


    const visibleSections = Utils.getSetting('visible_sections', {
      gallery: true,
      bio: true,
      menu: false,
      testimonials: false,
      coupons: false,
      announcement: false,
      use_theme_defaults: true
    });

    const filterMenuItemVisibility = (row) => {
      let visibilitySetting = visibleSections[row.code];

      if (visibilitySetting === undefined // i.e. always visible
          || visibilitySetting === true) {
        return row;
      }

      return null;
    }

    if (visibleSections.announcement === true) {
      setTimeout(() => {
        this.addNotification()
      }, 3000);
    }

    return (
        <RenderWhenMounted>
          <div sx={{
              overflow: 'hidden'
            }}
             css={`
                  ${Utils.getSetting('website_css')}
            `}>
              <SEO
                  title={Utils.getSetting('website_seo_page_title')}
                  keywords={Utils.getSetting('website_seo_page_keywords')}
                  description={Utils.getSetting('website_seo_page_description')}
                  favicon={Utils.getSetting('website_favicon_image')}
              />
              <Header logo={Utils.getSetting('website_top_header_logo')}
                      theme={theme} buttonUrl={Utils.getSetting('website_header_order_button_url')}
                      buttonText={Utils.getSetting('website_header_order_button_text')}
                      location={restaurant}
                      path={locationPath}
                      locationName={restaurantName}
                      filterMenuItemVisibility={filterMenuItemVisibility}
              />
              <Hero id="home"
                    raggedBottom
                    raggedBottomColor="white"
                    mainContent={Utils.getSetting('website_main_content')}
                    tagline={Utils.getSetting('website_main_content_tagline')}
                    orderPickupButtonText={Utils.getSetting('website_main_content_pickup_button_text')}
                    orderPickupButtonUrl={Utils.getSetting('website_main_content_pickup_button_url')}
                    orderDeliveryButtonText={Utils.getSetting('website_main_content_delivery_button_text')}
                    orderDeliveryButtonUrl={Utils.getSetting('website_main_content_delivery_button_url')}
                    heroImage={Utils.getSetting('website_main_content_hero_image')}
										showClickToCall={!!Utils.getSetting('website_main_content_show_click_to_call')}
                    locationName={restaurantName}
										locationPhone={restaurantPhone}
              />

              {visibleSections.gallery &&
                <Suspense fallback={<div />}>
                  <GalleryLazy id="gallery"
                           galleryTitle={Utils.getSetting('website_gallery_title')}
                           galleryTitleTagline={Utils.getSetting('website_gallery_title_tagline')}
                           gallery={Utils.getSetting('website_gallery')}
                           useThemeDefaults={visibleSections.use_theme_defaults}
                  />
                </Suspense>
              }

              {visibleSections.bio &&
                <Suspense fallback={<div />}>
                  <OurStoryLazy id="ourstory"
                            ourStoryTitle={Utils.getSetting('website_bio_title')}
                            ourStoryTagline={Utils.getSetting('website_bio_title_tagline')}
                            ourStoryText={Utils.getSetting('website_bio_text')}
                            ourStoryImage={Utils.getSetting('website_bio_image')}
                            useThemeDefaults={visibleSections.use_theme_defaults}
                  />
                </Suspense>
              }

              {visibleSections.menu &&
                <Suspense fallback={<div />}>
                  <MenuLazy id="menu"
                        raggedTop
                        raggedBottom
                        raggedTopColor="neutral100"
                        raggedBottomColor="neutral100"
                        menuTitle={Utils.getSetting('website_menu_title')}
                        menuTagline={Utils.getSetting('website_menu_title_tagline')}
                        menuButtonText={Utils.getSetting('website_menu_button_text')}
                        menuButtonUrl={Utils.getSetting('website_menu_button_url')}
                        heroImage={Utils.getSetting('website_menu_hero_image')}
                        menu={menu}
                        useThemeDefaults={visibleSections.use_theme_defaults}
                  />
                </Suspense>
              }

              {visibleSections.testimonials &&
                <Suspense fallback={<div />}>
                  <TestimonialsLazy id="reviews"
                                hasOrnaments
                                testimonialTitle={Utils.getSetting('website_testimonial_title')}
                                testimonialTagline={Utils.getSetting('website_testimonial_title_tagline')}
                                testimonials={Utils.getSetting('website_testimonials')}
                                useThemeDefaults={visibleSections.use_theme_defaults}
                  />
                </Suspense>
              }

              {visibleSections.coupons &&
                <Suspense fallback={<div />}>
                  <CouponsLazy id="coupons"
                           coupons={Utils.getSetting('website_coupons')}
                           couponTitle={Utils.getSetting('website_coupons_title')}
                           couponCaption={Utils.getSetting('website_coupons_title_tagline')}
                           useThemeDefaults={visibleSections.use_theme_defaults}
                  />
                </Suspense>
              }
              <Suspense fallback={<div />}>
                <FooterLazy id="footer"
                        logo={Utils.getSetting('website_top_header_logo')}
                        location={restaurant}
                        locationName={restaurantName}
                        mapUrl={mapUrl}
                        path={locationPath}
                        onlineOrderingUrl={onlineOrderingUrl}
                        filterMenuItemVisibility={filterMenuItemVisibility}
                        path={locationPath}
                />
              </Suspense>
              <ToastContainer />
          </div>
      </RenderWhenMounted>
    )
  }
}
