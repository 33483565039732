/** @jsx jsx */
import {useState, useEffect} from "react"
import {jsx} from "theme-ui"
import Logo from "../Navigation/Logo"
import SecondaryNav from "../Navigation/SecondaryNav"
import MainNav from "../Navigation/MainNav"

export default function Header({width, color, bg, ...props}) {
    const [scrolled, setScrolled] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10;
            if(isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener('scroll', handleScroll, {passive: true});

        return() => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [scrolled]);

    return(
        <div
            {...props}
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 10
            }}
        >
            <SecondaryNav location={props.location} path={props.path}/>
            <MainNav location={props.location} buttonUrl={props.buttonUrl} buttonText={props.buttonText} path={props.path} filterMenuItemVisibility={props.filterMenuItemVisibility}/>
            {props.logo &&
                <Logo variant="header" scrolled={scrolled} logo={props.logo} locationName={props.locationName} path={props.path}/>
            }
        </div>
    )
}