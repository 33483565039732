export const menuLeftItems = [
    {code: 'home', title: 'Home',to: '#home'},
    {code: 'gallery', title: 'Gallery', to: '#gallery'},
    {code: 'bio', title: 'Our Story', to: '#ourstory'},
    {code: 'menu', title: 'Menu', to: '#menu'}
]

export const menuRightItems = [
    {code: 'testimonials', title: 'Reviews', to: '#reviews'},
    {code: 'coupons', title: 'Coupons', to: '#coupons'}
]