/** @jsx jsx */
import { jsx } from 'theme-ui'
import RaggedBottomEdge from "./RaggedBottomEdge"
import RaggedTopEdge from "./RaggedTopEdge"

const DarkOverlay = ({raggedTop = false, raggedBottom = false, raggedTopColor = "white", raggedBottomColor = "white", ...props}) => {
    return (
    <div>
        <div sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: props.height ? props.height : '100%',
            bg: 'secondaryDarkerColor_70',
        }}>
            {raggedTop ? <RaggedTopEdge bg={raggedTopColor} /> : null}
            {raggedBottom ? <RaggedBottomEdge bg={raggedBottomColor} /> : null}
        </div>
        <div sx={{
                position: 'absolute',
                bottom: '-1rem',
                left: 0,
                display: 'block',
                width: '100%',
                height: '4rem',
            }} />
    </div>
)}

export default DarkOverlay