/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import { useStaticQuery, graphql } from "gatsby"
import Button from "../Button/button"
import IconButton from "../Button/IconButton"
import DarkOverlay from "../DarkOverlay/DarkOverlay"
// Icons
import Pickup from "../../icons/shopping-basket-smile.svg"
import Delivery from "../../icons/delivery-truck.svg"
import Phone from "../../icons/phone-circle.svg"

const HeroContent = ({mainContent, tagline, orderPickupButtonText, orderDeliveryButtonText, orderPickupButtonUrl, orderDeliveryButtonUrl, ...props}) => (
    <div sx={{
        position: 'relative',
        zIndex: 1
    }}>
            {mainContent
                ? <h1 sx={{fontFamily: 'heading', fontSize:[5,6], lineHeight:'heading'}}>{mainContent}</h1>
                : <h1 sx={{fontFamily: 'heading', fontSize:[5,6], lineHeight:'heading'}}>Welcome to<span sx={{display: 'block'}}>{props.locationName}</span></h1>
            }

        <h2 sx={{fontSize: 3, fontWeight: 'body', mt: 4, mb: 5}}>
            {tagline
                ? tagline
                : 'The best restaurant on planet Earth.'
            }
        </h2>
        <div>
            {(orderPickupButtonText || orderPickupButtonUrl) &&
                <a href={orderPickupButtonUrl
                    ? orderPickupButtonUrl
                    : '#'
                }>
                    <Button variant="primary"><IconButton><Pickup /></IconButton>
                        {orderPickupButtonText
                            ? orderPickupButtonText
                            : 'Order Pickup'
                        }
                    </Button>
                </a>
            }
            {(orderDeliveryButtonText || orderDeliveryButtonUrl) &&
                <a href={orderDeliveryButtonUrl
                    ? orderDeliveryButtonUrl
                    : '#'
                }>
                    <Button variant="primary" sx={{ml:[0,3,3], mt:[3,0,0]}}><IconButton><Delivery /></IconButton>
                        {orderDeliveryButtonText
                            ? orderDeliveryButtonText
                            : 'Order Delivery'
                        }
                    </Button>
                </a>
            }

					{props.showClickToCall &&
							<a href={`tel:${props.locationPhone}`}>
								<Button variant="primary" sx={{ml:[0,3,3], mt:[3,0,0]}}><IconButton><Phone /></IconButton>
									{props.locationPhone}
								</Button>
							</a>
					}
        </div>
    </div>
)

export default function Hero(props) {
    const heroImage = props.heroImage;

    const data = useStaticQuery(graphql`
        query {
            file(sourceInstanceName: {eq: "backgrounds"}, name: {eq: "header"}) {
                childImageSharp {
                 fluid(quality:90, maxWidth:2560) {
                    ...GatsbyImageSharpFluid_withWebp
                 }
                }
            }
        }
    `);

    const imageData = data.file.childImageSharp.fluid;

    return (
        <div>
                {heroImage
                ? <section
                       backgroundColor={`#040e18`}
                       id={props.id}
                       style={{
                           backgroundImage: `url("${props.heroImage}")`,
                           backgroundPosition: 'center',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                       }}
                       sx={{
                           height: '100vh',
                           minHeight: ['40rem', 'auto'],
                           pt: '5rem',
                           color: 'white',
                           textAlign: 'center',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                       }}>
                        <DarkOverlay
                            height="100vh"
                            raggedTop={props.raggedTop}
                            raggedBottom={props.raggedBottom}
                            raggedTopColor={props.raggedTopColor}
                            raggedBottomColor={props.raggedBottomColor}
                        />
                        <HeroContent
                            mainContent={props.mainContent}
                            tagline={props.tagline}
                            orderPickupButtonText={props.orderPickupButtonText}
                            orderDeliveryButtonText={props.orderDeliveryButtonText}
                            orderPickupButtonUrl={props.orderPickupButtonUrl}
                            orderDeliveryButtonUrl={props.orderDeliveryButtonUrl}
                            locationName={props.locationName}
														locationPhone={props.locationPhone}
														showClickToCall={props.showClickToCall}
                        />
                    </section>
                : <BackgroundImage
                    Tag="section"
                    fluid={imageData}
                    backgroundColor={`#040e18`}
                    id={props.id}
                    sx={{
                        height: '100vh',
                        minHeight: ['40rem', '47rem','47rem'],
                        pt: '5rem',
                        color: 'white',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <DarkOverlay raggedTop={props.raggedTop} raggedBottom={props.raggedBottom} raggedTopColor={props.raggedTopColor} raggedBottomColor={props.raggedBottomColor}/>

                    <HeroContent
                        mainContent={props.mainContent}
                        tagline={props.tagline}
                        orderPickupButtonText={props.orderPickupButtonText}
                        orderDeliveryButtonText={props.orderDeliveryButtonText}
                        orderPickupButtonUrl={props.orderPickupButtonUrl}
                        orderDeliveryButtonUrl={props.orderDeliveryButtonUrl}
                        locationName={props.locationName}
                    />
                </BackgroundImage>
            }
        </div>
    )
}
