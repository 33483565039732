/** @jsx jsx */
import { jsx } from "theme-ui"

export default function IconButton({...props}) {
    return (
        <div sx={{
            width: ['1.2rem','1rem'],
            height: ['1.2rem','1rem'],
            fill: 'white',
            mr: 2
        }}>{props.children}</div>
    )
}