/** @jsx jsx */
import { useBreakpointIndex } from '@theme-ui/match-media'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useState } from "react"
import { jsx } from "theme-ui"
import IconClose from "../../icons/close.svg"
import IconMenu from "../../icons/navigation-menu.svg"
import IconPhone from "../../icons/phone-circle.svg"
// Icons
import Dish from "../../icons/tools-kitchen-serving-dome.svg"
import Button from "../Button/button"
import IconButton from "../Button/IconButton"
import Layout from "../layout"
import { menuLeftItems, menuRightItems } from './MenuItems'


// MenuList
const MenuList = props => (
    <ul sx={{
        margin: 0,
        p: 0,
        listStyle: 'none'
    }}>{props.children}</ul>
)

// MenuListItem
const MenuListItem = props => (
    <li sx={{
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: [2,2,1],
        py: [3,3,1],
        pr: 2,
        pl: [5,5,0],
        mr: [0,0,25],
        display: ['block', 'block', 'inline-block'],
        
    }} >
        <AnchorLink to={`${props.path}${props.to}`} sx={{
        textDecoration: 'none', 
        color: 'white',
        '&:hover': {
            opacity: '0.5',
            cursor: 'pointer'
        }
    }} onClick={()=>props.setNavbarOpen(false)} onKeyDown={()=> {props.setNavbarOpen(false)}}>{props.children}</AnchorLink></li>
)

// The nav component
const NavBar = props => {
    const breakpointIndex = useBreakpointIndex();

    return (
    <div sx={{
        display: ['block', 'block', 'flex'],
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 3
        }}>
        <div>
            <MenuList>
            {menuLeftItems
                .filter(props.filterMenuItemVisibility)
                .map((item, index) => (
                <MenuListItem key={index} to={item.to} setNavbarOpen={props.setNavbarOpen} path={props.path}>{item.title}</MenuListItem>
            ))}
            </MenuList>
        </div>
        <div sx={{display: ['block','block','flex'],alignItems: 'center'}}>
            <MenuList>
                {menuRightItems
                    .filter(props.filterMenuItemVisibility)
                    .map((item, index) => (
                    <MenuListItem key={index} to={item.to} setNavbarOpen={props.setNavbarOpen} path={props.path}>{item.title}</MenuListItem>
                ))}
            </MenuList>
            <div sx={{
                '@media screen and (max-width: 68rem)': {
                    position: 'sticky',
                    boxSizing: 'border-box',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bg: 'secondaryDarkerColor',
                    borderTop: '1px solid rgba(0,0,0,0.5)',
                    p: 5,
                    mt: 3,

                    '> *': {
                        width: '100%'
                    }
                }
            }}>
                {(props.buttonUrl || props.buttonText) &&
                    <Button buttonUrl={props.buttonUrl} variant={breakpointIndex <= 1 ? "primary" : "small"}><IconButton><Dish /></IconButton> {props.buttonText ? props.buttonText : 'Order Online'}</Button>
                }
            </div>
        </div>
    </div>
)}

// The bar that appears on mobile - hamburger + phone icon
const MobileBar = props => (
    <div sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        '> *': {
            display: ['block','block','none'],
            width: '3.2rem',
            height: '3.2rem',
            p: '0.8rem',
            fill: 'white',
            '&:hover': {
                cursor: 'pointer'
            } 
        }
    }}> 
        <IconMenu onClick={() => (props.setNavbarOpen(!props.navbarOpen))} />
        <a href={props.phone ? `tel:${props.phone}` : 'tel:213213213'} title="Phone Number"><IconPhone /><span sx={{display: 'none'}}>{props.phone ? props.phone : '213213213'} </span></a>
    </div>
)

// The overlay background of the Offcanvas menu
const OverlayBackground = ({setNavbarOpen, navbarOpen, ...props}) => (
    <div sx={{
        position: 'absolute',
        bg: 'secondaryDarkerColor',
        width: '100%',
        height: '100vh',
        top: 0,
        left: `${navbarOpen ? 0 : '-100%'}`,
        opacity: 0.7,
        zIndex: 9998
    }} role="button" tabIndex="-1" aria-label="Close menu" onClick={() => setNavbarOpen(false)}  onKeyDown={() => setNavbarOpen(false)}><span sx={{display: 'none'}}>Overlay background</span></div>
)

// The container of the menu for mobile
const MobilemenuContainer = ({setNavbarOpen, navbarOpen, ...props}) => (
    <div sx={{
        position: 'fixed',
        width: '70%',
        maxWidth: '23rem',
        height: '100vh',
        overflow: 'scroll',
        top: 0,
        left: `${navbarOpen ? 0 : '-100%'}`,
        transition: '0.2s ease-in-out left',
        zIndex: 9999,
        bg: 'secondaryDarkerColor',
    }}>
        <div sx={{  
            width: '3.2rem',
            height: '3.2rem',
            p: '0.8rem',
            m: '1rem',
            fill: 'white',
            '&:hover': {
                cursor: 'pointer'
            } 
        }}><IconClose onClick={() => setNavbarOpen(false)} /></div>
        {props.children}
    </div>
)

// The main component
export default function MainNav({...props}) {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const breakpointIndex = useBreakpointIndex();
    const phone = (props.location && props.location.primary_phone) ? props.location.primary_phone : null;

    return(
        <div sx={{
            bg: 'secondaryDarkerColor',
            color: 'white',
        }}>
            <Layout variant="wide">
                {breakpointIndex > 1 ? (
                    <div>
                        <div>
                            <NavBar buttonUrl={props.buttonUrl} buttonText={props.buttonText} setNavbarOpen={setNavbarOpen} path={props.path} filterMenuItemVisibility={props.filterMenuItemVisibility}/>
                        </div>
                    </div>
                ) : (
                    <div>
                        <MobileBar phone={phone} setNavbarOpen={setNavbarOpen} navbarOpen={navbarOpen} />
                        <OverlayBackground setNavbarOpen={setNavbarOpen} navbarOpen={navbarOpen} />
                        <MobilemenuContainer setNavbarOpen={setNavbarOpen} navbarOpen={navbarOpen} >
                            <NavBar buttonUrl={props.buttonUrl} buttonText={props.buttonText} setNavbarOpen={setNavbarOpen} path={props.path} filterMenuItemVisibility={props.filterMenuItemVisibility}/>
                        </MobilemenuContainer>  
                    </div>
                )}
            </Layout>
        </div>
    )
}