/** @jsx jsx */
import {jsx} from "theme-ui"

export default function Layout({variant = 'normal', ...props}) {
    return (
        <div sx={{
            margin: '0 auto',
            px: [3,4,0],
            variant: `layouts.${variant}`
        }}>
            {props.children}
        </div>
    )
}