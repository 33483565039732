/** @jsx jsx */
import {useEffect, useRef, useState} from "react";
import {jsx} from "theme-ui";

const ScaledText = ({...props}) => {
	const ref = useRef(null);
	let [font, setFont] = useState(100);
	let maxWidth = props.maxWidth ? props.maxWidth : 300;

	useEffect( () => {
		if (ref.current.offsetWidth > maxWidth) {
			let newFont = font - 5;
			setFont(newFont);
		}
	}, [font, ref]);

	return (
		<div
			ref={ref}
			style={{
				height: '100%',
				fontSize: `${font}%`,
			}}
		>
			{props.children}
		</div>
	)
}

export default ScaledText;