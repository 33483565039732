import theme from "../gatsby-plugin-theme-ui/index"

export default {
    title: {
        fontSize: '14px',
        margin: '0px 0px 5px',
        color: theme.colors.black,
        fontWeight: 'bold',
        boxSizing: 'border-box'
    },
    message: {
        fontSize: '13px',
        color: theme.colors.black,
        marginBottom: '10px',
        boxSizing: 'border-box'
    },
    button: {
        borderRadius: '40px',
        textAlign: 'center',
        boxSizing: 'border-box',
        fontSize: '13px',
        width: '105%'
    },
    container: {
        fontFamily: theme.fonts.body,
        padding: 5,
        borderRadius: theme.radii[2],
        width: '270px'
    }
}
