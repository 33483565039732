/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import { jsx } from 'theme-ui'

const RaggedBottomEdge = ({bg, ...props}) => {
    const data = useStaticQuery(graphql`
        query {
            file(sourceInstanceName: {eq: "backgrounds"}, name: {eq: "separator-bottom"}) {
                childImageSharp {
                 fluid(quality:100) {
                    ...GatsbyImageSharpFluid_withWebp
                 }
                }
            }
        }      
    `)

    return(
        <div sx={{
            width: '100%',
            height: '38px',
            bg: (bg),
            position: 'absolute',
            bottom: 0,
            maskImage: `url(${data.file.childImageSharp.fluid.srcWebp})`,
            maskRepeat: 'repeat-x', 
        }} />
    )
}

export default RaggedBottomEdge